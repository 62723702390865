import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const reducer = (state, action) => {
  if (action.type === "LOADING_START") {
    return { ...state, isLoading: true };
  }
  if (action.type === "LOADING_END") {
    return { ...state, isLoading: false };
  }
  if (action.type === "UPDATE_DEMODATA") {
    console.log("UPDATE_DEMODATA");
    console.log(action.data);
    return {
      ...state,
      isLoading: false,
      preventRawReload: true,
      demoData: action.data,
    };
  }
  if (action.type === "UPDATE_DEMOID") {
    console.log("UPDATE_DEMOID");
    console.log(action.data);
    return {
      ...state,
      demoId: action.data,
    };
  }

  return state;
};

const initialState = {
  isLoading: false,
  demoId: "",
  demoData: [],
  preventRawReload: false,
};

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk));

export default createStore;
